<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!--      &lt;!&ndash; Brand logo&ndash;&gt;-->
      <!--      <b-link class="brand-logo">-->
      <!--        <vuexy-logo />-->
      <!--        <h2 class="brand-text text-primary ml-1">-->
      <!--          Vuexy-->
      <!--        </h2>-->
      <!--      </b-link>-->
      <!--      &lt;!&ndash; /Brand logo&ndash;&gt;-->

      <!-- Left Text-->
      <b-col lg="8" class="login-img" style="padding: 0 !important">
        <!--        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">-->
        <!--          <b-img-->
        <!--            fluid-->
        <!--            :src="imgUrl"-->
        <!--            alt="Login V2"-->
        <!--          />-->
        <!--        </div>-->
        <b-img :src="sideImg" alt="login" />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg pl-5 pr-5 pb-5 pt-1">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome back to Slothmove! 👋
          </b-card-title>
          <b-card-text class="mb-2"> Log in below in just a couple of seconds </b-card-text>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    @keyup="alertError = false"
                    @blur="handleValidatedEmail()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }"  v-if="!isNew">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      @keyup="handelChangePassword()"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div style="display: inline-flex" class="mb-2" v-if="isNew">
                <div
                  style="
                    width: 50px;
                    border: 1px solid #ccc;
                    height: 10px;
                    border-radius: 4px;
                    margin-left: 1%;
                  "
                  :class="{
                    red: passwordStrength && passwordStrength.score <= 1,
                    orange: passwordStrength && passwordStrength.score == 2,
                    yellow: passwordStrength && passwordStrength.score == 3,
                    green: passwordStrength && passwordStrength.score == 4,
                  }"
                ></div>
                <div
                  style="
                    border: 1px solid #ccc;
                    width: 50px;
                    height: 10px;
                    border-radius: 4px;
                    margin-left: 1%;
                  "
                  :class="{
                    grey: passwordStrength && passwordStrength.score <= 1,
                    orange: passwordStrength && passwordStrength.score == 2,
                    yellow: passwordStrength && passwordStrength.score == 3,
                    green: passwordStrength && passwordStrength.score == 4,
                  }"
                ></div>
                <div
                  style="
                    border: 1px solid #ccc;
                    width: 50px;
                    height: 10px;
                    border-radius: 4px;
                    margin-left: 1%;
                  "
                  :class="{
                    grey: passwordStrength && passwordStrength.score <= 2,
                    yellow: passwordStrength && passwordStrength.score == 3,
                    green: passwordStrength && passwordStrength.score == 4,
                  }"
                ></div>
                <div
                  style="
                    border: 1px solid #ccc;
                    width: 50px;
                    height: 10px;
                    border-radius: 4px;
                    margin-left: 1%;
                  "
                  :class="{
                    grey: passwordStrength && passwordStrength.score <= 3,
                    green: passwordStrength && passwordStrength.score == 4,
                  }"
                ></div>
              </div>

              <div
                v-if="isNew && passwordStrength.score < 3"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                <div class="alert-body font-small-2">
                  <p class="mb-0 text-center">
                    <small class="mr-50">
                      <span class="font-weight-bold">
                        Please make your password a bit more secure
                      </span>
                    </small>
                  </p>
                </div>
              </div>

              <b-form-group v-if="isNew">
                <div class="d-flex justify-content-between">
                  <label for="repeat-password">Repeat Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Repeat Password"
                  vid="repeatPassword"
                  :rules="{
                    required: isNew,
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="repeat-password"
                      v-model="repeatPassword"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="repeat-password"
                      placeholder="Repeat Password"
                      @keyup="alertError = false"
                      @change="handelChangePasswordRepeat()"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div
                v-if="alertError"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                <div class="alert-body font-small-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="position-absolute feather feather-help-circle"
                    style="top: 10px; right: 10px"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                  <p class="mb-0 text-center">
                    <small class="mr-50"
                      ><span class="font-weight-bold"
                        >User email or password are incorrect</span
                      ></small
                    >
                  </p>
                </div>
              </div>

              <div
                v-if="alertNoRegisted"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                <div class="alert-body font-small-2">
                  <p class="mb-0 text-center">
                    <small class="mr-50">
                      <span class="font-weight-bold">
                        Please create an account by clicking on the link below
                      </span>
                    </small>
                  </p>
                </div>
              </div>

              <div
                v-if="passwordDifferent"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                <div class="alert-body font-small-2">
                  <p class="mb-0 text-center">
                    <small class="mr-50">
                      <span class="font-weight-bold"> passwords do not match </span>
                    </small>
                  </p>
                </div>
              </div>

              <b-button
                type="submit"
                variant="primary"
                block
                style="background-color: #18a8d6"
                class="d-flex justify-content-center align-items-center"
                :disabled="invalid"
              >
                <b-spinner v-if="loading" small class="mr-1" :variant="'light'" />
                <p class="mb-0">{{ textBtn }}</p>
              </b-button>
            </b-form>
          </validation-observer>

          <div class="mt-3 d-flex justify-content-center" v-if="!isNew">
            <b-link
              :href="`https://form.slothmove.com/`"
              target="_blank"
              class="d-flex align-items-center"
            >
              <span>Create an account</span>
            </b-link>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import jwtDefaultConfig from "@core/auth/jwt/jwtDefaultConfig";
import axiosCustom from "@axios";

const zxcvbn = require("zxcvbn");

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: `${this.$urlBucketImage}/login.jpg`,
      textBtn: "Sign in",
      userEmailNewAccount: "",
      passwordNewAccount: "",
      repeatPassword: "",
      passwordStrength: {},
      passwordDifferent: false,
      // validation rules
      jwtConfig: jwtDefaultConfig,
      required,
      email,
      alertError: false,
      alertNoRegisted: false,
      loading: false,
      isNew: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    proceedLogin() {
      useJwt
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then((response) => {
          useJwt.setToken(response.data.accessToken);
          useJwt.setRefreshToken(response.data.refreshToken);
          localStorage.setItem("userData", JSON.stringify(response.data));
          // It is recommended to store the userDate in the Vuex store, so it will be available in the global state of the app and in a reactive way.
          this.$store.commit("app/setField", ["user", response.data]);
          //

          const moves = response.data.moves;

          //Set Global Current - Last Move
          // console.log(moves);

          // moves[moves.length - 1].referrer = "theBunch"
          // moves[moves.length - 1]["allDataProvided"] = false
          // moves[moves.length - 1]['services'].informMobile = true
          // moves[moves.length - 1]['verifiedDetails'] = false
          // moves[moves.length - 1]['moveDetails']['moveInDate'] = '2023-09-12'

          this.$store.commit("app/setField", ["lastMove", moves[moves.length - 1]]);
          this.$store.commit("app/setField", ["currentMove", moves[moves.length - 1]]);

          //Set Pending Actions Custom
          // moves[moves.length - 1]['pendingActions'].dvlaLicence.pending = true
          // moves[moves.length - 1]['pendingActions'].dvlaVehicle.pending = true

          //Whether the user provided all the information in the forms
          if (moves[moves.length - 1]["allDataProvided"]) {
            this.$store.commit("app/setField", ["showDVLAMiniForm", false]);
            this.$store.commit("app/setField", ["showWizardForm", false]);
          }

          //Intercom Init Config

          let m = moves[moves.length - 1];

          window.intercomSettings = {
            api_base: "https://api-iam.eu.intercom.io",
            app_id: "dyqvf88v",
            name: `${m.userDetails.firstName} ${m.userDetails.firstName}` , // Full customer name
            user_id: m['intercomId'], // IntercomId field inside the move
            email: m.userDetails.email// the email for the customer
          };

          this.$ability.update(response.data.ability);
          this.loading = false;
           this.$router.replace(getHomeRouteForLoggedInUser(response.data.role)).then(() => {
            /* const { verifiedDetails } = moves[moves.length - 1];

            if (verifiedDetails) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome back ${response.data.firstName} 👋`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `This is your personalised dashboard where you can check what you updated at any time`,
                },
              });
            } */
          });
        })
        .catch((error) => {
          console.error(error);
          this.alertError = true;
          this.loading = false;
          // this.$refs.loginForm.setErrors(error.response.data.error);
        });
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          if (this.isNew) {
            this.createdAccount();
          } else {
            this.proceedLogin();
          }
        }
      });
    },
    createdAccount() {
      if (this.passwordStrength.score < 3) {
        this.loading = false;
        return;
      }

      if (this.password === this.repeatPassword) {
        this.loading = true;
        axiosCustom
          .post(`user/utils/newPasswd`, {
            email: this.userEmail,
            password: this.password,
          })
          .then(({ data }) => {
            if (data) {
              this.proceedLogin();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.passwordDifferent = true;
        this.loading = false;
      }
    },
    handelChangePassword() {
      this.alertError = false;
      this.passwordDifferent = false;
      this.passwordStrength = zxcvbn(this.password);
    },
    handelChangePasswordRepeat() {
      this.passwordDifferent = false;
    },
    handleValidatedEmail() {
      if (!this.userEmail) {
        this.isNew = false;
        return;
      }
      this.alertNoRegisted = false;
      this.loading = true;
      this.passwordDifferent = false;
      axiosCustom
        .get(`user/utils/existsWithP?email=${this.userEmail}`)
        .then(({ data }) => {
          this.isNew = true;
          if (data) {
            this.isNew = false;
            this.textBtn = "Sign in";
            return;
          }
          this.textBtn = "Sign up";
        })
        .catch(() => {
          this.textBtn = "Sign in";
          this.isNew = false;
          this.alertNoRegisted = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query.new == "{{email}}") {
      window.history.replaceState(
        null,
        null,
        window.location["origin"] + window.location["pathname"]
      );
      return;
    }

    if (this.$route.query.new) {
      this.isNew = true;
      this.userEmail = this.$route.query.new;
    }
  },
  beforeMount() {
    this.creds = this.$route.query.red;
    if (this.creds) {
      const head = {
        "Access-Control-Request-Method": "POST",
        "Access-Control-Request-Headers": "content-type",
      };
      /*  this.creds = window.atob(decodeURIComponent(this.creds));
      const s = this.creds.split(":"); */
      axiosCustom
        .post(
          "api/auth/autologin",
          {
            key: this.creds,
          },
          { headers: head }
        )
        .then((response) => {
          this.userEmail = response.data.email;
          this.password = response.data.password;
          this.proceedLogin();
        })
        .catch();
      /*  // eslint-disable-next-line prefer-destructuring
      this.userEmail = s[0];
      // eslint-disable-next-line prefer-destructuring
      this.password = s[1] */
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.btn-primary {
  border-color: #18a8d6 !important;
  background-color: #18a8d6 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #18a8d6 !important;
  background-color: #18a8d6 !important;
}

.login-img {
  height: 100vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .login-img {
    height: 40vh;
  }
}

.toastification {
  .badge-success {
    background-color: #23a9d7 !important;
  }

  .toastification-title {
    color: #23a9d7 !important;
  }
}

.red {
  background-color: #ee1936;
}

.green {
  background-color: green;
}

.grey {
  background-color: #ddd;
}
.yellow {
  background-color: yellow;
}
.orange {
  background-color: orangered;
}
</style>
